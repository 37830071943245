<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-white py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <notifications></notifications>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5 mt-movil">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Restablecer contraseña</small>
              </div>
              <loading-overlay :active.sync="loading" :is-full-page="true" />
              <form class="needs-validation" @submit.prevent="recaptcha()">
                <base-input
                  id="input-identificacion"
                  v-mask="mascara"
                  alternative
                  label="Identificación:"
                  class="mb-3"
                  name="Identification"
                  prepend-icon="fas fa-address-card"
                   placeholder="#### ##### #####"
                  v-model="form.data.attributes.identification"
                  rules="required"
                  :value="form.data.attributes.identification.toUpperCase()"
                  @input="form.data.attributes.identification = $event.toUpperCase()"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.identification" />
                <div class="text-xs-center" style="text-align: center; padding-bottom: 5%">
                  <vue-recaptcha style="display: inline-block" ref="recaptcha" sitekey="6LfnSo4fAAAAAMHmdeL0DyAyQDlTqaNZyUOjkr_h" @verify="verify" @expired="expired"></vue-recaptcha>
                </div>
                <div class="text-center">
                  <!-- <base-button type="submit" @click.prevent="handleSubmit" class="my-4">Sign in</base-button> -->
                  <base-button :disabled="validation" native-type="submit" class="my-4" type='primary' id="button-identificacion"
                    >Restablecer contraseña</base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>



import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { MASK } from "../../utilities/constants";
import { VueRecaptcha } from "vue-recaptcha";
//import axios from 'axios';

export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError, VueRecaptcha }, 
  data() {
    return {
      form: {
        data: {
          type: "password-forgot",
          attributes: {            
            redirect_url: process.env.VUE_APP_BASE_URL + "password/email",
            identification:  localStorage.getItem("identification") ?  localStorage.getItem("identification") : '',
          },
        },
      },
      loading: false,
      mascara: MASK[0].value,
      reCAPTCHASolved: false,
      prueba:{},
    };
  },
  methods: {
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute(this.recaptcha());
    },
    verify(response) {
      this.reCAPTCHASolved = true;
    },
    expired() {
      this.reCAPTCHASolved = false;
    },
    async recaptcha () {
      //await this.$recaptchaLoaded()
      //const token = await this.$recaptcha('reset')
      //await axios.post('https://api.clubdefacturas.com/api/v1/recaptcha',{response: token })
      //.then(data =>{
        //const resp = data.data;
        //console.log(resp)
        //if(resp.data.score >= 0.7){
          this.handleSubmit();
        //}else{
          //this.$notify({
            //type: "danger",
            //message: "Ocurrió un error, intenta más tarde.",
          //});
        //}
      //}).catch( error =>{
        //console.log(error)
      //})
    },
    async handleSubmit() {
      this.loading=true;
      /* if (this.$isDemo == 1) {
        await this.$notify({
          type: "danger",
          message: "Password reset is disabled in the demo.",
        });
        return;
      } */
      try {
        this.form.data.attributes.identification = this.form.data.attributes.identification.split(" ").join("")
        await this.$store.dispatch("reset/forgotPassword", this.form.data);
        this.$notify({
          type: "success",
          message: "Se ha enviado un correo con el enlace para restablecer su contraseña.",
        });
        this.loading = false;
       
      } catch (error) {
        
        this.sendError("Recover password", error, this.form.data);
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        /* await this.$store.dispatch("errors/sendError", {
            plataforma: "Tombola del éxito / Clavulin",
            metodo: "Recover password",
            error: JSON.stringify(error.response),
            data: JSON.stringify(this.form.data)
          }); */
        this.$notify({
          type: "danger",
          message: "Ocurrió un problema, por favor verifica tu identificación.",
        });
        this.loading=false;
        this.setApiValidation(error.response.data.errors);
      }
    },

     async sendError(metodo, error, data) {
      await this.$store.dispatch("errors/sendError", {
          plataforma: "Tombola del éxito / Clavulin",
          metodo:  metodo,
          error:  JSON.stringify(error),
          data:  JSON.stringify(data)
        });
    },
  },
  computed:{
    validation:function(){
      return this.form.data.attributes.identification == null || this.form.data.attributes.identification == '' || !this.reCAPTCHASolved;
    }
  }
};
</script>
<style scoped>

/* Escritorio pequeño o tablet vertical */
@media (max-width: 991px) and (min-width: 500px) {
  .mt-movil {
    margin-top: -3rem !important;
  }
}
  /* Movil */
@media (max-width: 499px) {
  .mt-movil {
    margin-top: -3rem !important;
  }
}
</style>